import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

//scss
import './Index.scss'

//comment index

//images
import Logo from '../../__images/logo.png'
import FundosAberto from '../../__images/icos/fundos_aberto.png'
import CarteiraAdminstrada from '../../__images/icos/carteira_administrada.png'
import FundosExclusivo from '../../__images/icos/fundos_exclusivo.png'
import { api } from '../../_api/app'

export default function Index(props) {

    const [loading, setLoading] = useState(true)

    const [apiHome, setApiHome] = useState(
        {
            botaoBanner: {},
            servicos: []
        }
    )

    useEffect(() => {
        
        if  ( props.location.pathname ===  '/contato' ){

            var alturaContato = document.getElementById('contato').offsetTop

            window.scrollTo({
                top: alturaContato,
                behavior: "smooth"
            })

        }

        loadHome()

    }, [props])

    async function loadHome(){
        
        var botaoBanner = await api.get('/home')
        var servicos = await api.get('/home/servicos')

        var response = { 
            botaoBanner: botaoBanner.data[0], 
            servicos: servicos.data
        }

        setApiHome( response )
        setLoading(false)


    }

    return(
        <div id="index">

            <div id="banner">

                <img alt="logo" src={Logo}/>
                <h1>{loading === false ? apiHome.botaoBanner.nome : <Skeleton/>}</h1>
                <span style={{cursor: 'pointer'}} onClick={() =>
                window.scrollTo({
                    top: document.getElementById('servicos').offsetTop,
                    behavior: "smooth"
                })}>Descubra</span>

            </div>

            <section id="servicos">

                <div className="container">

                    <h2>O que oferecemos</h2>

                    <div className="servicos">

                        { loading === true && <Skeleton count={3} className="servico"></Skeleton> }

                        { apiHome.servicos.map((row, key) =>
                        <div key={key} className="servico">
                            <i style={{backgroundImage: 'url('+(key === 0 && FundosAberto || key === 1 && CarteiraAdminstrada || key === 2 && FundosExclusivo)+')'}}></i>
                            <h4>{row.titulo}</h4>
                            <div dangerouslySetInnerHTML={{__html: row.descricao}}/>
                            <Link to={row.link}>Conhecer</Link>

                        </div>
                        )}

                    </div>

                </div>
                
            </section>

        </div>
    )

}
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { api } from '../../_api/app'

//scss
import './Fundos.scss'

//images
import IcoDownload from '../../__images/icos/download.png'
export default function Fundos(props) {

    const [loading, setLoading] = useState(true)
    const [fundoOpen, setFundoOpen] = useState('')

    const [apiFundos, setApiFundos] = useState({
        fundos: []
    })

    useEffect(() => {
        
        loadApi()

    }, [props])

    async function loadApi(){
        
        var fundos = await api.get('/fundos')

        var response = { 
            fundos: fundos.data
        }

        console.log( response )

        setApiFundos( response )

        setLoading(false)

    }

    return(
        <div id="fundos">

            <div id="banner">

                <div className="container">

                    <h1>Fundos</h1>

                    <p>A Kilima está montando opções especiais de fundos para você. </p>
                    <button>Esteja entre os primeiros. <b>Inscreva-se na nossa newsletter.</b></button>

                </div>

            </div>

            <div className="fundos-lista">

                <div className="container">

                    { loading === true && <Skeleton count={3}/> }

                    { apiFundos.fundos.map((row, key) => 
                    <div key={key} className={key === fundoOpen ? 'fundo opened' : 'fundo'} onClick={() => fundoOpen === key ? setFundoOpen(4253643634) : setFundoOpen(key)}>

                        <div className="front">
                            {row.nome}
                        </div>

                        <div className="back">

                            <div className="texto" dangerouslySetInnerHTML={{__html: row.texto}}/>

                            <div className="infos">

                                <div className="item">

                                    <h4>{row.nome}</h4>
                                    <h5 dangerouslySetInnerHTML={{__html: row.descricao}}/>

                                </div>

                                <div className="item">
                                    <b>Política de Investimento</b>

                                    <div>
                                        Gestor: <span>{row.politica_investimento.gestor}</span>
                                    </div>
                                    <div>
                                        Administrador: <span>{row.politica_investimento.administrador}</span>
                                    </div>
                                    <div>
                                        Auditor: <span>{row.politica_investimento.auditor}</span>
                                    </div>
                                    <div>
                                        Custodiante: <span>{row.politica_investimento.custodiante}</span>
                                    </div>
                                </div>

                                <div className="item">
                                    <b>Movimentações</b>

                                    <div>
                                        Investimento Inicial: <span>{row.movimentacoes.investimento_inicial}</span>
                                    </div>
                                    <div>
                                        Movimentação Mínima: <span>{row.movimentacoes.movimentacao_minima}</span>
                                    </div>
                                    <div>
                                        Saldo Mínimo: <span>{row.movimentacoes.saldo_minimo}</span>
                                    </div>
                                    <div>
                                        Cota da Aplicação: <span>{row.movimentacoes.cota_da_aplicacao}</span>
                                    </div>
                                    <div>
                                        Cota do Resgate: <span>{row.movimentacoes.cota_do_resgate}</span>
                                    </div>
                                    <div>
                                        Pagamento do Resgate: <span>{row.movimentacoes.pagamento_do_resgate}</span>
                                    </div>
                                </div>

                                <div className="item">
                                    <div>
                                        Classificação Anbima: <span>{row.demais.classificacao_anbina}</span>
                                    </div>
                                    <div>
                                        Taxa de Administração: <span>{row.demais.taxa_de_administracao}</span>
                                    </div>
                                    <div>
                                        Taxa de Performance: <span>{row.demais.taxa_de_performance}</span>
                                    </div>
                                    <div>
                                        Taxa de Saída Antecipada: <span>{row.demais.taxa_de_saida_antecipada}</span>
                                    </div>
                                    <div>
                                        Tributação: <span>{row.demais.tributacao}</span>
                                    </div>
                                </div>

                                <div className="item">
                                    <a rel="noopener noreferrer" target="_blank" href={row.uploads.relatorio_mensal}>
                                        <img alt="Download" src={IcoDownload}/>
                                        <span>Relatório mensal</span>
                                    </a>

                                    <a rel="noopener noreferrer" target="_blank" href={row.uploads.regulamento}>
                                        <img alt="Download" src={IcoDownload}/>
                                        <span>Regulamento</span>
                                    </a>

                                    <a rel="noopener noreferrer" target="_blank" href={row.uploads.documentos}>
                                        <img alt="Download" src={IcoDownload}/>
                                        <span>Documentos</span>
                                    </a>
                                </div>

                            </div>

                        </div>

                    </div>
                    )}
                </div>

            </div>

        </div>
    )

}
import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { api } from '../../_api/app'

//scss
import './Compliance.scss'

//images
import IcoDownload from '../../__images/icos/download.png'

export default function Compliance(props) {

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [assunto, setAssunto] = useState('')
    const [mensagem, setMensagem] = useState('')

    const [canalDenuncia, setCanalDenuncia] = useState(false)

    const [loading, setLoading] = useState(true)

    const [apiCompliance, setApiCompliance] = useState({
        compliances: []
    })

    useEffect(() => {
        
        loadApi()

    }, [props])

    async function loadApi(){
        
        var compliances = await api.get('/compliances')

        var response = { 
            compliances: compliances.data
        }

        setApiCompliance( response )

        setLoading(false)

    }

    async function handleDenuncia(e){

        
        e.preventDefault()

        var data = {
            nome, email, assunto, mensagem
        }

        var send = await api.post('/canal-denuncia', data)

        if ( send.data.error === true ) {
            alert(send.data.message)
        } else {
            alert('Contato enviado com sucesso')
        }
        
        setNome('') 
        setEmail('')
        setAssunto('')
        setMensagem('')

    }

    return(
        <div id="compliance">

            <div id="banner">

                <div className="container">

                    <h1>Compliance</h1>

                    <p>faça download dos documentos atualizados.</p>

                </div>

            </div>

            <div className="compliance-lista">

                <div className="container">

                    { loading === true && <Skeleton count={3}/>}
                    { apiCompliance.compliances.map((row,key)=>
                    <a key={key} rel="noopener noreferrer" target="_blank" href={row.link}>
                        <img alt="Download" src={IcoDownload}/>
                        <span>{row.nome}</span>
                    </a>
                    )}

                    <button onClick={() => setCanalDenuncia(true)}>Canal de denúncia</button>

                </div>

            </div>

            <div id="canal-denuncia" className={canalDenuncia === true ? 'opened' : ''}>

                <div className="box">

                    <button onClick={() => setCanalDenuncia(false)}></button>

                    <h3>Canal de denúncia</h3>

                    <form onSubmit={handleDenuncia}>

                        <input required type="text" placeholder="Nome *" value={nome} onChange={(e) => setNome(e.target.value)}/>
                        <input required type="text" placeholder="E-mail *" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <input required type="text" placeholder="Assunto *" value={assunto} onChange={(e) => setAssunto(e.target.value)}/>
                        <textarea required placeholder="Mensagem *" value={mensagem} onChange={(e) => setMensagem(e.target.value)}/>
                        <button>Enviar</button>

                        <div style={{fontSize: '9px', color: '#FFF', marginTop: '20px'}}>
                        As informações aqui inseridas serão tratadas pela área de Compliance da Kilima com a mais absoluta discrição e independência. <br/>O nosso canal de denúncias  deve ser utilizado com responsabilidade, e por isso, recomendamos que ele seja usado quando se tenha indícios ou evidências efetivas de que ações não conformes ocorreram ou estejam ocorrendo por parte da Kilima ou de seus colaboradores. Utilizando este canal, esteja ciente que quanto mais detalhadas forem as suas informações, melhor será a sua contribuição e mais ela nos ajudará a identificá-la e apurá-la. Por fim, caso você queira se identificar, não tem problema, a sua identidade será sempre preservada, fique tranquilo!
                        </div>

                    </form>

                </div>

            </div>

        </div>
    )

}
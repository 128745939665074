import React, { useEffect } from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

export default function Layout(props){

    useEffect(()  => {

        if ( props.location.pathname !== '/contato' ){
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }

    }, [props])

    return(
        <>
        <Header theme={(props.location.pathname === '/' || props.location.pathname === '/contato') ? 'dark' : 'light'} pathname={props.location.pathname}/>
            {props.children}
        <Footer home={(props.location.pathname === '/' || props.location.pathname === '/contato') ? true : false}/>
        </>
    )

}
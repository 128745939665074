const axios = require('axios');

var baseUrl

if(process.env.NODE_ENV === 'development') {
  baseUrl = 'http://api.kilima.somocollab.com/';
} else if (process.env.NODE_ENV === 'test') {
  baseUrl = 'http://api.kilima.somocollab.com/';
} else {
  baseUrl = 'http://api.kilima.somocollab.com/'
}

const api = axios.create({
  baseURL: baseUrl
})

const TOKEN = window.localStorage.getItem('token')

export { api, TOKEN }
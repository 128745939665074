import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { api } from '../../_api/app'

//scss
import './QuemSomos.scss'

//images
import Logo from '../../__images/logo-quem-somos.png'
import IcoMouse from '../../__images/icos/mouse.png'

export default function QuemSomos(props) {

    const [loading, setLoading] = useState(true)
    const [pessoaOpen, setPessoaOpen] = useState('')

    const [apiQuemSomos, setApiQuemSomos] = useState({
        texto: '',
        equipe: []
    })

    useEffect(() => {
        
        loadApi()

    }, [props])

    async function loadApi(){
        
        var botaoBanner = await api.get('/quem-somos')
        var servicos = await api.get('/quem-somos/equipe')

        var response = { 
            texto: botaoBanner.data[0].texto, 
            equipe: servicos.data
        }

        setApiQuemSomos( response )

        setLoading(false)

    }

    return(
        <div id="quem-somos">

            <div id="banner">

                <div className="container">

                    <h1>Quem somos</h1>

                    <div className="header">

                        <div className="logo">
                            <img alt="Logo" src={Logo}/>
                        </div>

                        { loading === true ? <div className="text" style={{opacity: '.1'}}><Skeleton count={18}/></div> :
                        <div className="text" dangerouslySetInnerHTML={{__html: apiQuemSomos.texto}}/>
                        }
                    </div>

                    <button>
                        <img alt="Mouse" src={IcoMouse}/>
                    </button>

                </div>

            </div>

            <div id="equipe">

                <div className="container">

                    <h3>Nossa Equipe</h3>

                    { loading === true && <Skeleton count={3}/> }

                    { apiQuemSomos.equipe.map((row, key) => 
                    <div key={key} className={key === pessoaOpen ? 'pessoa opened' : 'pessoa'} onClick={() => setPessoaOpen(key)}>

                        <div className="front">
                            <i></i>
                            <b>{row.nome}</b>
                            <span>&nbsp;&nbsp;|&nbsp;&nbsp;{row.cargo}</span>
                        </div>

                        <div className="infos">
                            <div className="foto" style={{backgroundImage: 'url('+row.foto+')'}}></div>
                            <div className="texto" dangerouslySetInnerHTML={{__html: row.texto}}/>
                        </div>
                    </div>
                    )}

                </div>

            </div>

        </div>
    )

}
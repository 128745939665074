import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { hotjar } from 'react-hotjar';

//scss
import './Header.scss'

//images
import LogoMenu from '../../__images/logo-menu.png'

export default function Header({pathname, theme, props}){

    hotjar.initialize(2504373, 6);
    hotjar.identify('2504373', { userProperty: 'value' });

    const [opened, setOpened] = useState(false)

    useEffect(() => {

        setOpened(false)

    }, [pathname])

    return(
        <>
        <div className="head">
            <button className={opened === true ? 'opened' : ''} onClick={opened === true ? () => setOpened(false) : () => setOpened(true)}>
                <span></span>
            </button>
        </div>
        <header className={opened === true ? 'opened' : ''} id={theme ? theme : 'light'}>

            <div className="container">

                <ul>
                    <li className={(pathname !== '/' && pathname !== '/contato') ? 'logo active' : 'logo inactive'}>
                        <Link to="/" className={pathname === '/' ? 'active' : 'inactive'}>
                            <img alt="Logo" src={LogoMenu}/>
                        </Link>
                    </li>
                    <li>
                        <Link to="/quem-somos" className={pathname === '/quem-somos' ? 'active' : 'inactive'}>
                            <span>Quem somos</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/fundos" className={pathname === '/fundos' ? 'active' : 'inactive'}>
                            <span>Fundos</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/relatorios" className={pathname === '/relatorios' ? 'active' : 'inactive'}>
                            <span>Relatórios</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/compliance" className={pathname === '/compliance' ? 'active' : 'inactive'}>
                            <span>Compliance</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/contato" className={pathname === '/contato' ? 'active' : 'inactive'}>
                            <span>Contato</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </header>
        </>
    )

}

import React, { useState } from 'react'
import { api } from '../../_api/app'

//scss
import './Footer.scss'

//images
import SeloFooter from '../../__images/selo-footer.svg'
import LogoFooter from '../../__images/logo-footer.png'
import IcoFace from '../../__images/icos/facebook.png'
import IcoInsta from '../../__images/icos/instagram.png'
import IcoLinke from '../../__images/icos/linkedin.png'
import IcoTwitter from '../../__images/icos/twitter.png'
import setaBranca from '../../__images/icos/seta_branca.png'
import IcoEmail from '../../__images/icos/email.png'
import IcoLocal from '../../__images/icos/local.png'

export default function Footer({home = false}){

    const [nome, setNome] = useState('')
    const [email, setEmail] = useState('')
    const [assunto, setAssunto] = useState('')
    const [mensagem, setMensagem] = useState('')

    const [newsLetter,  setNewsLetter] = useState('')

    const dados = {
        contato: 'contato@kilima.com.br',
        endereco: 'Rua Jesuíno Cardoso, 454 - Vila Nova Conceição',
        endereco2: 'São Paulo - SP, Conjunto 92',
        facebook: '/',
        instagram: '/',
        linkedin: '/',
        twitter: '/',
    }
    

    async function handleNewsLetter(e){

        e.preventDefault()

        var data = {
            email: newsLetter
        }

        var send = await api.post('/newsletter', data)

        if ( send.data.error === true ) {
            alert(send.data.message)
        } else {
            alert('Contato enviado com sucesso')
        }

        setNewsLetter('') 

    }

    async function handleContato(e){

        e.preventDefault()

        var data = {
            nome, email, assunto, mensagem
        }

        var send = await api.post('/contato', data)

        if ( send.data.error === true ) {
            alert(send.data.message)
        } else {
            alert('Contato enviado com sucesso')
        }
        
        setNome('') 
        setEmail('')
        setAssunto('')
        setMensagem('')

    }

    function toTop(){
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }

    return(
        <footer className={home ? 'home' : 'no-home'}>

            { home === true &&
            <div id="contato">

                <h2>Contato</h2>

                <div className="container">

                    <form onSubmit={handleContato}>

                        <input type="text" required placeholder="Nome *" value={nome} onChange={(e) => setNome(e.target.value)}/>
                        <input type="text" required placeholder="E-mail *" value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <input type="text" required placeholder="Assunto *" value={assunto} onChange={(e) => setAssunto(e.target.value)}/>
                        <textarea required placeholder="Mensagem *" value={mensagem} onChange={(e) => setMensagem(e.target.value)}/>
                        <button>Enviar</button>

                    </form>

                    <div className="footer-home">

                        <div>
                            <img alt="Logo" src={LogoFooter}/>
                        </div>

                        <div>
                            <img alt="Email" src={IcoEmail}/>
                            <p>{dados.contato}</p>
                        </div>

                        <div>
                            <img alt="Local" src={IcoLocal}/>
                            <p>{dados.endereco}<br/>{dados.endereco2}</p>
                        </div>

                        <div className="newsletter">

                            <h5>Inscreva-se na nossa newsletter</h5>

                            <form className="label" onSubmit={handleNewsLetter}>
                                <input required placeholder="E-mail*" onChange={(e) => setNewsLetter(e.target.value)} value={newsLetter}/>
                                <button>
                                    <img alt="Enviar" src={setaBranca}/>
                                </button>
                            </form>

                        </div>

                        <div className="social">
                            {dados.facebook && <a href={dados.facebook} rel="noopener noreferrer" target="_blank"><img alt="Facebook" src={IcoFace}/></a>}
                            {dados.instagram && <a href={dados.instagram} rel="noopener noreferrer" target="_blank"><img alt="Instagram" src={IcoInsta}/></a>}
                            {dados.linkedin && <a href={dados.linkedin} rel="noopener noreferrer" target="_blank"><img alt="Linkedin" src={IcoLinke}/></a>}
                            {dados.twitter && <a href={dados.twitter} rel="noopener noreferrer" target="_blank"><img alt="Twitter" src={IcoTwitter}/></a>}
                        </div>

                    </div>

                </div>

            </div>
            }
            
            <div className="texto-legal">

                <div className="container">

                    <button onClick={toTop}>Voltar ao topo da página</button>

                    <div>
                        <div>
                            <p>Este material não constitui uma oferta, aconselhamento, sugestão ou solicitação de aquisição de cotas de fundos de investimento, tendo caráter simplesmente informativo. A rentabilidade obtida no passado não representa garantia de resultados futuros. Leia o prospecto e o regulamento antes de investir em qualquer fundo. Os investimentos em fundos não são garantidos pelo , pelo gestor, por qualquer mecanismo de seguro ou, ainda, pelo fundo garantidor de crédito – FGC (https://www.fgc.org.br/). Para avaliação da performance do fundo é recomendável uma análise de, no mínimo, 12 meses. A rentabilidade divulgada não é líquida de impostos. Fundos, assim como outros valores mobiliários, estão sujeitos a perda do capital investido. A Kilima seus sócios, administradores, colaboradores e prepostos não se responsabilizam por quaisquer fatos resultantes do uso das informações aqui apresentadas, uma vez que qualquer decisão de investimento deve ser tomada exclusivamente pelo investidor. Supervisão e Fiscalização: Comissão de Valores Mobiliários – CVM. Serviço de Atendimento ao Cidadão em http://www.cvm.gov.br.</p>
                        </div>
                        <div>
                            <img alt="Selo" src={SeloFooter}/>
                        </div>
                    </div>

                </div>

            </div>

            { home === false  &&
            <div className="footer-blue">
                <div className="container">

                    <div className="logo-social">

                        <img alt="Logo" src={LogoFooter}/>

                        <div className="social">
                        {dados.facebook && <a href={dados.facebook} rel="noopener noreferrer" target="_blank"><img alt="Facebook" src={IcoFace}/></a>}
                            {dados.instagram && <a href={dados.instagram} rel="noopener noreferrer" target="_blank"><img alt="Instagram" src={IcoInsta}/></a>}
                            {dados.linkedin && <a href={dados.linkedin} rel="noopener noreferrer" target="_blank"><img alt="Linkedin" src={IcoLinke}/></a>}
                            {dados.twitter && <a href={dados.twitter} rel="noopener noreferrer" target="_blank"><img alt="Twitter" src={IcoTwitter}/></a>}
                        </div>

                    </div>

                    <div className="local">

                        <h5>Onde estamos</h5>
                        <p>{dados.endereco}<br/>{dados.endereco2}</p>

                    </div>

                    <div className="newsletter">

                        <h5>Inscreva-se na nossa newsletter</h5>

                        <form className="label" onSubmit={handleNewsLetter}>
                            <input placeholder="E-mail*"/>
                            <button>
                                <img alt="Enviar" src={setaBranca}/>
                            </button>
                        </form>

                    </div>

                </div>
                
            </div>
            }

            <div className="powered">
                <div className="container">
                    <span><b>®2020 KILIMA ASSET</b>. Todos os direitos reservados</span>
                    <a rel="noopener noreferrer" target="_blank" href="https://somocollab.com.br">Produzido por <b>SOMO Collab</b></a>
                </div>
            </div>

        </footer>
    )

}

import React, { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { api } from '../../_api/app'

//scss
import './Relatorios.scss'

//images
export default function Relatorios(props) {

    const [loading, setLoading] = useState(true)

    const [apiRelatorios, setApiRelatorios] = useState({
        relatorios: []
    })

    useEffect(() => {
        
        loadApi()

    }, [props])

    async function loadApi(){
        
        var relatorios = await api.get('/relatorios')

        var response = { 
            relatorios: relatorios.data
        }

        setApiRelatorios( response )

        setLoading(false)

    }

    function loadApis(){
        
        setTimeout(function(){

            setApiRelatorios({
                relatorios: [
                    {
                        ano: '2020',
                        data: [
                            {
                                mes: 'Junho',
                                link: 'Link...'
                            },
                            {
                                mes: 'Maio',
                                link: 'Link...'
                            },
                            {
                                mes: 'Abril',
                                link: 'Link...'
                            },
                            {
                                mes: 'Março',
                                link: 'Link...'
                            },
                            {
                                mes: 'Fevereiro',
                                link: 'Link...'
                            },
                            {
                                mes: 'Janeiro',
                                link: 'Link...'
                            }
                        ]
                    },
                    {
                        ano: '2019',
                        data: [
                            {
                                mes: 'Dezembro',
                                link: 'Link...'
                            }
                        ]
                    }
                ]
            })

            setLoading(false)

        }, 2000)

    }

    return(
        <div id="relatorios">

            <div id="banner">

                <div className="container">

                    <h1>Relatórios</h1>

                    <p>Confira os relatórios dos nosso fundos.</p>

                </div>

            </div>

            <div className="relatorios-lista">

                { loading === true && <div className="ano"><Skeleton count={4}/></div>}

                { apiRelatorios.relatorios.map((row, key) => 
                <div key={key} className="ano">

                    <h3>{row.ano}</h3>

                    <div className="meses">
                        {row.data.map((rew, key2) => 
                            <a rel="noopener noreferrer" key={key2} target="_blank" href={rew.link}>{rew.mes}</a>
                        )}
                    </div>

                </div>
                )}

            </div>

        </div>
    )

}
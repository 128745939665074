import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom'

//views
import Index from './_views/Index/Index'
import QuemSomos from './_views/QuemSomos/QuemSomos'

//components
import Layout from './_components/Layout/Layout'
import Fundos from './_views/Fundos/Fundos'
import Relatorios from './_views/Relatorios/Relatorios'
import Compliance from './_views/Compliance/Compliance'
import Lp from './_views/LP/Lp'

//export function default
export default function Routes(props) {

  useEffect(() => {

  }, [props])

  function NoMatch() {

    let location = useLocation()
  
    return (
      <div id="page-no-banner">
        <section>
          <div className="container">
            <h1>Pagina nao encontrada.</h1>
            <h3>
              <code>{location.pathname}</code>
            </h3>
          </div>
        </section>
      </div>
    )

  }

  // function PrivateRoute({ children, ...rest }) {

  //   return (
  //     <Route
  //         {...rest}
  //       render={({ location }) =>
  //         window.localStorage.getItem('token') ? (
  //             children
  //         ) : (
  //           <Redirect
  //             to={{
  //               pathname: "/login",
  //               state: { from: location }
  //             }}
  //           />
  //         )
  //       }
  //     />
  //   )

  // }

  return(

      <Router>

        <Switch>
          
          <Layout>
              <Route exact path="/" component={Index}/>
              <Route exact path="/contato" component={Index}/>
              <Route exact path="/quem-somos" component={QuemSomos}/>
              <Route exact path="/fundos" component={Fundos}/>
              <Route exact path="/relatorios" component={Relatorios}/>
              <Route exact path="/compliance" component={Compliance}/>
              <Route exact path="/lp" component={Lp}/>
          </Layout>

          <Route path="*">
            <NoMatch />
          </Route>

        </Switch>

      </Router>

    )

}